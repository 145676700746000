@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-residencial-white;
  }

  .banner-mask {
    mask: url(/img/mobile/banner-mask.svg) center calc(100% - 85px) no-repeat;
  }

  @media (min-width: 1024px) {
    .banner-mask {
      mask: url(/img/desktop/banner-mask.svg) center calc(100% - 55px) no-repeat;
    }
  }

  @media (min-width: 1920px) {
    .banner-mask {
      mask: url(/img/desktop/banner-mask-2x.svg) center calc(100% - 55px)
        no-repeat;
    }
  }

  .policy p {
    margin-top: 1rem;
  }

  .emphasis strong {
    @apply text-3xl font-semibold;
  }
}

html body .leaflet-control-container {
  z-index: 998 !important;
  position: relative;
}
